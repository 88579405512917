import styled from "styled-components"
import React from "react"

const SBannerWithText = styled.div`
  width: 100%;
  min-height: 337px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.site.primaryDark};
`

const BannerWithTextInner = styled.div`
  width: 100%;
  max-width: 992px;
`

const BannerWithText = ({ children }) => (
  <SBannerWithText>
    <BannerWithTextInner>{children}</BannerWithTextInner>
  </SBannerWithText>
)

export default BannerWithText
