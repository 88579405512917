import * as React from "react"
import styled from "styled-components"

const ContentContainer = styled.div`
  padding: 120px 30px;
  max-width: 1052px; // 992 + 60
`

const Content = styled.div`
  > ol {
    &,
    > li > ol {
      list-style: none;
      padding: 0;
    }

    > li,
    > li > ol > li {
      > :first-child::before {
        display: inline-block;
        min-width: 70px;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    > li > ol > li:first-child {
      margin-top: 1em;
    }

    > li {
      counter-increment: outer;

      > :first-child::before {
        content: counter(outer) ".";
      }

      > ol {
        > li {
          counter-increment: inner;

          > :first-child::before {
            content: counter(outer) "." counter(inner);
          }
        }
      }
    }
  }

  .list-letters {
    padding-left: 1.5em;

    > li {
      position: relative;
      padding-left: 1.5em;

      > :first-child::before {
        display: none;
      }

      ::before {
        content: counter(outer, lower-alpha) ")";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`

const WysiwygContainer = ({ html }) => {
  return (
    <ContentContainer>
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </ContentContainer>
  )
}

export default WysiwygContainer
